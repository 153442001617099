// src/pages/Documentos/index.tsx

import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    TextField,
    useTheme,
    useMediaQuery,
    IconButton,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Tooltip,
    CircularProgress,
    Autocomplete,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar, ptBR } from '@mui/x-data-grid';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import DocumentosService from "../../services/documentosService";
import { useAuth } from '../../context/useAuth';
import { allowedRoles } from "../../configs/apiConfigs";
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/Upload';
import { useDropzone } from 'react-dropzone';
import { DocumentoType } from '../../models/Documentos';
import CooperadosService from '../../services/cooperadosService';
import { CooperadosType } from '../../models/Cooperados';


interface Documento {
    id: number;
    id_usuario: number;
    descricao: string;
    caminho: string;
    nome_arquivo: string;
    data_criacao: string;
    tipo_documento: string;
    tamanho: number;
    extensao: string;
    status: string;
    tags: string;
    versao: number;
    visibilidade: string;
    data_inicio_vigencia: string;
    data_fim_vigencia: string;
}

const DocumentosPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useAuth();
    const [documentos, setDocumentos] = useState<DocumentoType[]>([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [documentTypes, setDocumentTypes] = useState<string[]>(["RG", "CNH", "CPF", "Certidão Casamento"]);
    const [fileTypes, setFileTypes] = useState<{ [key: string]: string }>({});
    const [fileNames, setFileNames] = useState<{ [key: string]: string }>({}); // Novo estado para nomes editados
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [isUploading, setIsUploading] = useState(false);
    // Estado para armazenar a lista de cooperados
    const [cooperados, setCooperados] = useState<CooperadosType[]>([]);



    // Para editar documento
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [currentDocumento, setCurrentDocumento] = useState<Documento | null>(null);
    const [editData, setEditData] = useState<Partial<Documento>>({});
    const [vigenciaInicio, setVigenciaInicio] = useState<{ [key: string]: string }>({});
    const [vigenciaFim, setVigenciaFim] = useState<{ [key: string]: string }>({});
    // Estado para armazenar o cooperado selecionado
    const [selectedCooperado, setSelectedCooperado] = useState<CooperadosType | null>(null);


    const podeMostrarBotao = allowedRoles.includes(user?.role ?? '');

    const fetchDocumentos = async () => {
        try {
            let response;
            if (allowedRoles.includes(user?.role ?? '')) {
                response = await DocumentosService.getAllDocumentos();



            } else {
                response = await DocumentosService.getDocumentosByUserId(user?.cd_entidade!);
            }
            setDocumentos(response);
        } catch (error) {
            console.error("Erro ao buscar documentos:", error);
        }
    };

    // Função para buscar os cooperados
    const fetchCooperados = async () => {
        try {
            let response;
            if (allowedRoles.includes(user?.role ?? '')) {

                response = await CooperadosService.getAllItens();
                setCooperados(response.map(coop => ({
                    ...coop,
                    ds_entidade: `${coop.ds_entidade} | CPF/CNPJ: ${coop.nr_cpfcnpj} | Entidade: ${coop.cd_entidade} `
                })));

            }

        } catch (error) {
            console.error("Erro ao buscar cooperados:", error);
        }
    };
    useEffect(() => {
        fetchCooperados(); // Chame a função para buscar os cooperados
        fetchDocumentos();

    }, []);

    // Configuração do react-dropzone
    const onDrop = (acceptedFiles: File[]) => {
        setSelectedFiles([...selectedFiles, ...acceptedFiles]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'image/*': ['.jpeg', '.jpg', '.png'],
            'application/msword': ['.doc', '.docx'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            // Adicione outros tipos conforme necessário
        },
    });

    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            alert("Por favor, selecione pelo menos um arquivo.");
            return;
        }
        if (!selectedCooperado) {
            alert("Por favor, selecione um cooperado.");
            return;
        }

        // Validar se todos os arquivos têm um tipo de documento selecionado
        for (let file of selectedFiles) {
            if (!fileTypes[file.name]) {
                alert(`Por favor, selecione o tipo de documento para ${file.name}.`);
                return;
            }
        }

        // Validar se todos os arquivos têm um nome editado
        for (let file of selectedFiles) {
            if (!fileNames[file.name]) {
                alert(`Por favor, edite o nome do arquivo para ${file.name}.`);
                return;
            }
        }

        const documentosToUpload = selectedFiles.map(file => ({
            file,
            tipo_documento: fileTypes[file.name] || "Outro",
            nome_arquivo: fileNames[file.name] || file.name,
            data_inicio_vigencia: vigenciaInicio[file.name] || '', // Novo campo
            data_fim_vigencia: vigenciaFim[file.name] || '',
            id_usuario: selectedCooperado.cd_entidade, // Adiciona o id_usuario

        }));

        setIsUploading(true);

        try {
            const response = await DocumentosService.createDocumentos(documentosToUpload);
            alert(response.length > 0 ? "Documentos criados com sucesso!" : "Nenhum documento foi criado.");
            setOpenDialog(false);
            setSelectedFiles([]);
            setFileTypes({});
            setFileNames({});
            fetchDocumentos();
        } catch (error: any) {
            console.error("Erro ao fazer upload dos documentos:", error.message);
            alert(`Erro ao fazer upload dos documentos: ${error.message}`);
        } finally {
            setIsUploading(false);
        }
    };

    const handleDelete = async (id: number) => {
        if (!window.confirm("Tem certeza que deseja deletar este documento?")) return;

        try {
            await DocumentosService.deleteDocumento(id);
            alert("Documento deletado com sucesso.");
            fetchDocumentos();
        } catch (error) {
            console.error("Erro ao deletar documento:", error);
            alert("Erro ao deletar documento.");
        }
    };

    const handleEditOpen = (documento: Documento) => {
        setCurrentDocumento(documento);
        setEditData({
            descricao: documento.descricao,
            tipo_documento: documento.tipo_documento,
            status: documento.status,
            tags: documento.tags,
            visibilidade: documento.visibilidade,
            data_inicio_vigencia: documento.data_inicio_vigencia,
            data_fim_vigencia: documento.data_fim_vigencia,
        });
        setEditDialogOpen(true);
    };

    const handleEditSave = async () => {
        if (!currentDocumento) return;

        try {
            await DocumentosService.updateDocumento(currentDocumento.id, editData);
            alert("Documento atualizado com sucesso.");
            setEditDialogOpen(false);
            setCurrentDocumento(null);
            setEditData({});
            setVigenciaFim({});
            setVigenciaInicio({});
            fetchDocumentos();
        } catch (error) {
            console.error("Erro ao atualizar documento:", error);
            alert("Erro ao atualizar documento.");
        }
    };

    const handleAddDocumentType = () => {
        const newType = prompt("Digite o novo tipo de documento:");
        if (newType && !documentTypes.includes(newType)) {
            setDocumentTypes([...documentTypes, newType]);
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'nome_cooperado',
            headerName: 'Cooperado',
            flex: 1,
        },
        {
            field: 'nome_arquivo',
            headerName: 'Nome do Arquivo',
            flex: 2,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Tooltip title={`Clique para baixar ${params.value}`}>
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() => window.open(`/documentos/${params.row.caminho.split('/').pop()}`, '_blank')}
                        startIcon={<DescriptionIcon />}
                    >
                        {params.value}
                    </Button>
                </Tooltip>
            ),
        },
        {
            field: 'descricao',
            headerName: 'Descrição',
            flex: 1,
        },
        {
            field: 'tipo_documento',
            headerName: 'Tipo de Documento',
            flex: 1,
        },
        {
            field: 'data_criacao',
            headerName: 'Data de Criação',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => (
                <Typography color={colors.greenAccent[500]}>
                    {params.value
                        ? new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(new Date(params.value))
                        : 'N/A'}
                </Typography>
            ),
        },
        {
            field: 'data_inicio_vigencia', // Nova coluna
            headerName: 'Início Vigência',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                const date = new Date(params.value!);
                const isValid = !isNaN(date.getTime());
                return (
                    <Typography color={colors.greenAccent[500]}>
                        {isValid
                            ? new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(date)
                            : 'N/A'}
                    </Typography>
                );
            },
        },
        {
            field: 'data_fim_vigencia', // Nova coluna
            headerName: 'Fim Vigência',
            flex: 1,
            renderCell: (params: GridRenderCellParams<string>) => {
                const date = new Date(params.value!);
                const isValid = !isNaN(date.getTime());
                return (
                    <Typography color={colors.greenAccent[500]}>
                        {isValid
                            ? new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(date)
                            : 'N/A'}
                    </Typography>
                );
            },
        },

        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params: GridRenderCellParams) => (



                podeMostrarBotao ? (
                    <>
                        <IconButton
                            color="primary"
                            onClick={() => handleEditOpen(params.row)}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            color="error"
                            onClick={() => handleDelete(params.row.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </>
                ) : null
            ),
        },
    ];

    return (
        <Box m="20px">
            <Header title="Gerenciar Documentos" subtitle="CRUD de Documentos" />

            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb="20px"
                flexDirection={isMobile ? 'column' : 'row'}
                gap={2}
            >


                {podeMostrarBotao && (
                    <Button
                        variant="contained"
                        startIcon={<UploadIcon />}
                        onClick={() => setOpenDialog(true)}
                    >
                        Adicionar Documentos
                    </Button>
                )}

            </Box>

            <Box
                sx={{
                    height: '75vh',
                    width: '100%',
                    '& .MuiDataGrid-root': {
                        backgroundColor: colors.primary[400],
                    },
                    '& .MuiDataGrid-cell': {
                        color: colors.grey[100],
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        borderBottom: 'none',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        backgroundColor: colors.primary[400],
                    },
                    '& .MuiDataGrid-footerContainer': {
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        borderTop: 'none',
                    },
                    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                        color: `${colors.grey[100]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={documentos}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 25, 50]}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>

            {/* Dialog para Upload de Documentos */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
                <DialogTitle>Upload de Documentos</DialogTitle>
                <DialogContent>
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: '2px dashed #ccc',
                            borderRadius: '5px',
                            padding: '20px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            mb: 2,
                        }}
                    >
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <Typography>Solte os arquivos aqui...</Typography> :
                                <Typography>Arraste e solte os arquivos aqui, ou clique para selecionar</Typography>
                        }
                    </Box>

                    {/* Campo de seleção de cooperado */}
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <Autocomplete
                            options={cooperados}
                            getOptionLabel={(option) => option.ds_entidade || ''}
                            value={selectedCooperado}
                            onChange={(event: any, newValue: CooperadosType | null) => {
                                setSelectedCooperado(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Selecione o Cooperado" variant="outlined" />
                            )}
                        // Opcional: Define se a Autocomplete deve permitir apenas seleção de opções existentes
                        // disableClearable={true} // Remove o botão de limpar seleção, se desejado
                        />
                    </FormControl>

                    {selectedFiles.length > 0 && (
                        <Box>
                            {selectedFiles.map((file, index) => (
                                <Box key={index} display="flex" alignItems="center" mb={2} flexDirection={isMobile ? 'column' : 'row'} gap={2}>
                                    <Typography sx={{ mr: 2 }}>{file.name}</Typography>
                                    <FormControl fullWidth>
                                        <InputLabel>Tipo de Documento</InputLabel>
                                        <Select
                                            value={fileTypes[file.name] || ''}
                                            label="Tipo de Documento"
                                            onChange={(e) => setFileTypes({
                                                ...fileTypes,
                                                [file.name]: e.target.value as string
                                            })}
                                        >
                                            {documentTypes.map((type, idx) => (
                                                <MenuItem key={idx} value={type}>{type}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        label="Nome do Arquivo"
                                        value={fileNames[file.name] || file.name}
                                        onChange={(e) => setFileNames({
                                            ...fileNames,
                                            [file.name]: e.target.value
                                        })}
                                        fullWidth
                                    />

                                    <TextField
                                        label="Data de Início da Vigência"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={vigenciaInicio[file.name] || ''}
                                        onChange={(e) => setVigenciaInicio({
                                            ...vigenciaInicio,
                                            [file.name]: e.target.value
                                        })}
                                        fullWidth
                                    />
                                    <TextField
                                        label="Data de Fim da Vigência"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={vigenciaFim[file.name] || ''}
                                        onChange={(e) => setVigenciaFim({
                                            ...vigenciaFim,
                                            [file.name]: e.target.value
                                        })}
                                        fullWidth
                                    />



                                </Box>
                            ))}
                            <Button variant="text" onClick={handleAddDocumentType}>
                                Adicionar Novo Tipo de Documento
                            </Button>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setOpenDialog(false);
                        setSelectedFiles([]);
                        setFileTypes({});
                        setFileNames({});
                        setVigenciaInicio({});
                        setVigenciaFim({});

                    }}>Cancelar</Button>
                    <Button variant="contained" onClick={handleUpload} disabled={selectedFiles.length === 0 || isUploading}>
                        {isUploading ? <CircularProgress size={24} /> : "Upload"}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog para Editar Documento */}
            <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Editar Documento</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} mt={1}>
                        <TextField
                            label="Descrição"
                            value={editData.descricao || ''}
                            onChange={(e) => setEditData({ ...editData, descricao: e.target.value })}
                            fullWidth
                        />
                        <FormControl fullWidth>
                            <InputLabel>Tipo de Documento</InputLabel>
                            <Select
                                value={editData.tipo_documento || ''}
                                label="Tipo de Documento"
                                onChange={(e) => setEditData({ ...editData, tipo_documento: e.target.value as string })}
                            >
                                {documentTypes.map((type, idx) => (
                                    <MenuItem key={idx} value={type}>{type}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={editData.status || ''}
                                label="Status"
                                onChange={(e) => setEditData({ ...editData, status: e.target.value as string })}
                            >
                                <MenuItem value="ativo">Ativo</MenuItem>
                                <MenuItem value="arquivado">Arquivado</MenuItem>
                                <MenuItem value="excluido">Excluído</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Tags"
                            value={editData.tags || ''}
                            onChange={(e) => setEditData({ ...editData, tags: e.target.value })}
                            fullWidth
                        />
                        {/* Novos campos para datas de vigência */}
                        <TextField
                            label="Data de Início da Vigência"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={editData.data_inicio_vigencia || ''}
                            onChange={(e) => setEditData({ ...editData, data_inicio_vigencia: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            label="Data de Fim da Vigência"
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={editData.data_fim_vigencia || ''}
                            onChange={(e) => setEditData({ ...editData, data_fim_vigencia: e.target.value })}
                            fullWidth
                        />

                        <FormControl fullWidth>
                            <InputLabel>Visibilidade</InputLabel>
                            <Select
                                value={editData.visibilidade || ''}
                                label="Visibilidade"
                                onChange={(e) => setEditData({ ...editData, visibilidade: e.target.value as string })}
                            >
                                <MenuItem value="privado">Privado</MenuItem>
                                <MenuItem value="publico">Público</MenuItem>
                                <MenuItem value="restrito">Restrito</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialogOpen(false)}>Cancelar</Button>
                    <Button variant="contained" onClick={handleEditSave}>
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

};

export default DocumentosPage;
