// src/services/documentosService.ts

import { DocumentoType } from "../models/Documentos";
import { ENDPOINTS } from "../configs/apiConfigs"; // Ajuste o caminho conforme necessário
import axiosInstance from './axiosConfig'; // Ajuste o caminho conforme necessário

const axios = axiosInstance;

class DocumentosService {
    private API_URL = ENDPOINTS.documentos;

    /**
     * Método para obter todos os documentos
     */
    public async getAllDocumentos(): Promise<DocumentoType[]> {
        try {
            const response = await axios.get(this.API_URL);
            return response.data as DocumentoType[];
        } catch (error) {
            throw new Error(`Erro ao obter documentos: ${error}`);
        }
    }

    /**
     * Método para contar a quantidade de documentos
     */
    public async countDocumentos(): Promise<number> {
        try {
            const response = await axios.get(this.API_URL);
            return response.data.length;
        } catch (error) {
            throw new Error(`Erro ao contar documentos: ${error}`);
        }
    }

    /**
     * Método para obter um documento por ID
     * @param id - ID do documento
     */
    public async getDocumentoById(id: number): Promise<DocumentoType> {
        try {
            const response = await axios.get(`${this.API_URL}/${id}`);
            return response.data as DocumentoType;
        } catch (error) {
            throw new Error(`Erro ao obter documento: ${error}`);
        }
    }

    /**
     * Método para obter documentos por ID de usuário
     * @param id_usuario - ID do usuário
     */
    public async getDocumentosByUserId(id_usuario: number): Promise<DocumentoType[]> {
        try {
            const response = await axios.get(`${this.API_URL}/user/${id_usuario}`);
            return response.data as DocumentoType[];
        } catch (error) {
            throw new Error(`Erro ao obter documentos por usuário: ${error}`);
        }
    }

    /**
     * Método para criar novos documentos com upload de arquivos
     * @param documentos - Array de objetos contendo arquivos e seus tipos
     */
    public async createDocumentos(documentos: { file: File; tipo_documento: string, nome_arquivo: string, data_inicio_vigencia: string; data_fim_vigencia: string; id_usuario:any }[]): Promise<DocumentoType[]> {
        try {
            const formData = new FormData();

            documentos.forEach((doc, index) => {
                formData.append(`documentos[${index}][file]`, doc.file);
                formData.append(`documentos[${index}][tipo_documento]`, doc.tipo_documento);
                formData.append(`documentos[${index}][nome_arquivo]`, doc.nome_arquivo); // Adiciona o nome editado
                formData.append(`documentos[${index}][data_inicio_vigencia]`, doc.data_inicio_vigencia); // Adicionado
                formData.append(`documentos[${index}][data_fim_vigencia]`, doc.data_fim_vigencia);       // Adicionado
                formData.append(`documentos[${index}][id_usuario]`, doc.id_usuario);       // Adicionado

                
                console.log(doc);
                
            });

            const response = await axios.post(`${this.API_URL}/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data.documentos as DocumentoType[];
        } catch (error: any) {
            if (error.response) {
                // Erro vindo do servidor
                throw new Error(error.response.data.message || 'Erro ao criar documentos.');
            } else {
                // Outro tipo de erro
                throw new Error('Erro ao criar documentos.');
            }
        }
    }

    /**
     * Método para atualizar um documento existente
     * @param id - ID do documento
     * @param documento - Dados a serem atualizados
     */
    public async updateDocumento(id: number, documento: Partial<DocumentoType>): Promise<DocumentoType> {
        try {
            const response = await axios.put(`${this.API_URL}/update/${id}`, documento);
            return response.data as DocumentoType;
        } catch (error) {
            throw new Error(`Erro ao atualizar documento: ${error}`);
        }
    }

    /**
     * Método para deletar um documento por ID
     * @param id - ID do documento
     */
    public async deleteDocumento(id: number): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/delete/${id}`);
        } catch (error) {
            throw new Error(`Erro ao deletar documento: ${error}`);
        }
    }

    /**
     * Método para deletar todos os documentos
     */
    public async deleteAllDocumentos(): Promise<void> {
        try {
            await axios.delete(`${this.API_URL}/delete-all`);
        } catch (error) {
            throw new Error(`Erro ao deletar todos os documentos: ${error}`);
        }
    }
}

export default new DocumentosService();
